import { getAddressLine } from 'components/PropertyCard/Address/helpers';
import { PropertyList } from 'types/app';
import { Address } from 'types/property';
import { ReapitEntityTypes, ReapitDocument } from 'views/Documents/types';

export const getFilteredDocumentList = (
  documentList: ReapitDocument[],
  showLettings: boolean,
  propertyList: PropertyList,
  showVendorDocs: boolean,
) => {
  if (!documentList) {
    return [];
  }

  const relevantDocuments: ReapitDocument[] = documentList.filter((doc) => {
    if (
      doc.associatedType === ReapitEntityTypes.CONTACT ||
      (doc.associatedType === ReapitEntityTypes.LANDLORD && showLettings) ||
      (doc.associatedType === ReapitEntityTypes.VENDOR && showVendorDocs) ||
      (doc.associatedType === ReapitEntityTypes.CERTIFICATE && showLettings) ||
      (doc.associatedType === ReapitEntityTypes.TENANCY && showLettings)
    ) {
      return true;
    }

    if (
      doc.associatedType === ReapitEntityTypes.PROPERTY &&
      showVendorDocs &&
      showLettings
    ) {
      return true;
    }

    if (doc.associatedType === ReapitEntityTypes.PROPERTY && !showLettings) {
      return propertyList?.selling?.some(
        (property) => property.id === doc.associatedId,
      );
    }

    if (doc.associatedType === ReapitEntityTypes.PROPERTY && !showVendorDocs) {
      return propertyList?.letting?.some(
        (property) =>
          property.id === doc.associatedId ||
          property.tenancyDetails?.some((t) => t.id === doc.associatedId) ||
          property.id === doc.certificateInfo?.propertyId,
      );
    }

    return false;
  });

  const filteredDocumentListWithProperty = relevantDocuments.map((doc) => {
    if (doc.associatedType !== ReapitEntityTypes.PROPERTY) {
      return doc;
    }
    const property = [...propertyList.selling, ...propertyList.letting].find(
      (prop) => prop.id === doc.associatedId,
    );
    return {
      ...doc,
      address: property?.address
        ? getAddressLine(property.address as Address, true)
        : '',
    };
  });

  return filteredDocumentListWithProperty;
};
