import React, { useEffect, useMemo, useState } from 'react';
import cookies from 'browser-cookies';
import {
  GrowthBook,
  GrowthBookProvider,
  FeatureDefinition,
} from '@growthbook/growthbook-react';
import axios from 'axios';
import Loading from 'components/Loading/Loading';
import { FlagCookies } from 'utils/helpers/detection/types';
import { decodeJwt } from 'utils/helpers/jwt/jwt';

interface FeatureFlagProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

type ProfileData = {
  MemberId: string;
  GpsProfileId: string;
  reapit_contact_code: string;
};

const GROWTHBOOK_API = 'https://cdn.growthbook.io/api/features/';

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  children,
}) => {
  const bearerToken =
    sessionStorage.getItem(
      `oidc.user:${window.appConfig.authServerUri}:savills-web`,
    ) || cookies.get('GpsBearer');

  const reapitContactCode = bearerToken?.includes('profile')
    ? JSON.parse(bearerToken || '{}')?.profile?.reapit_contact_code
    : undefined;

  const profileData = useMemo(
    () => decodeJwt<ProfileData>(bearerToken || ''),
    [bearerToken],
  );

  const [features, setFeatures] = useState<
    Record<string, FeatureDefinition<unknown>>
  >({});

  useEffect(() => {
    async function fetchFeatures() {
      const featureResponse = await axios<{
        features: Record<string, FeatureDefinition<unknown>>;
      }>({
        method: 'GET',
        url: `${GROWTHBOOK_API}${window.appConfig.growthbookKey}`,
      });
      setFeatures(featureResponse.data.features);
    }
    fetchFeatures();
  }, []);

  const growthbook = useMemo(() => {
    if (Object.keys(features).length) {
      const attributes = Object.values(FlagCookies).reduce((acc, cookie) => {
        acc[cookie] = cookies.get(cookie) || undefined;
        return acc;
      }, {} as { [index: string]: string | undefined });
      return new GrowthBook({
        features,
        attributes: {
          ...attributes,
          contactId: reapitContactCode || profileData?.reapit_contact_code,
        },
      });
    }
    return null;
  }, [features, profileData?.reapit_contact_code, reapitContactCode]);
  return growthbook ? (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  ) : (
    <Loading />
  );
};
