import React from 'react';

import CardTag from 'components/Card/CardTag';
import { Statuses } from 'components/Card/types';
import { SellingStatus } from 'types/property';
import {
  CardTagWrapper,
  PropertyCardTagWrapper,
  PropertyCardTitle,
  CardLink,
} from '../PropertyCard.styled';
import { MarketingPortalTagsProps } from '../types';
import { MARKETING_OFFLINE_STATUSES } from '../constants';
import { getMarketingPortalTags } from './helpers';
import { MarketingReportID } from './constants';

const MarketingPortalTags: React.FC<MarketingPortalTagsProps> = ({
  marketingPortals,
  property,
  t9n,
  hideTitle,
}) => {
  if (
    property.sellingStatus === SellingStatus.ForSaleUnavailable ||
    property.sellingStatus === SellingStatus.Completed
  ) {
    return null;
  }

  const marketingPortalTags = getMarketingPortalTags(marketingPortals || []);

  if (
    !property.internetAdvertising &&
    MARKETING_OFFLINE_STATUSES.includes(property.sellingStatus as SellingStatus)
  )
    return (
      <PropertyCardTagWrapper>
        <CardTag
          tags={[
            {
              title: t9n.PropertyCard.Tag.MarketingOffline,
              status: Statuses.INFORMATION,
            },
          ]}
        />
      </PropertyCardTagWrapper>
    );

  if (!marketingPortalTags.length) return null;

  return (
    <PropertyCardTagWrapper>
      {!hideTitle && (
        <PropertyCardTitle>
          {t9n.PropertyCard.Tag.MarketingPortals}:
        </PropertyCardTitle>
      )}
      <CardTagWrapper>
        {marketingPortalTags.map((tag) => (
          <CardLink to={`#${MarketingReportID}`} smooth key={tag.title}>
            <CardTag
              tags={[
                {
                  title: tag.title,
                  status: tag.status as Statuses,
                },
              ]}
            />
          </CardLink>
        ))}
      </CardTagWrapper>
    </PropertyCardTagWrapper>
  );
};

export default MarketingPortalTags;
