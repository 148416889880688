import { ROUTES } from 'constants/routes';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { ManagedType } from 'views/Landlord/types';
import {
  CompanyPortfolio,
  LettingStatus,
  PortfolioProperties,
  PortfolioProperty,
  PortfolioTenancy,
  PortfolioTypes,
  SidebarPortfolioProperty,
} from 'types/property';
import {
  ACTIVE_TENANCIES,
  HIDDEN_TENANCIES,
  INACTIVE_TENANCIES,
  SECONDARY_TENANCIES,
} from './constants';

export const formatProperties = (properties: PortfolioProperty[]) => {
  const allProperties: SidebarPortfolioProperty[] = properties?.reduce(
    (acc: SidebarPortfolioProperty[], property: PortfolioProperty) => {
      if (
        !property?.propertyId ||
        !property.address ||
        HIDDEN_TENANCIES.includes(property.status)
      ) {
        return acc;
      }

      const propertyTenancies =
        property.tenancies.filter(
          (tenancy) => !HIDDEN_TENANCIES.includes(tenancy.status),
        ) || [];

      const baseProperty = {
        propertyId: property.propertyId,
        address: property.address,
        link: `${ROUTES.LETTING.ROOT}/${property.propertyId}`,
        status: property.status,
        isManaged: property.lettingType === ManagedType.MANAGED,
      };

      if (
        propertyTenancies.length === 0 &&
        property.status !== LettingStatus.DeterminedByTenancies
      ) {
        return [
          ...acc,
          {
            propertyId: property.propertyId,
            address: property.address,
            link: `${ROUTES.LETTING.ROOT}/${property.propertyId}`,
            status: property.status,
            isManaged: property.lettingType === ManagedType.MANAGED,
          },
        ];
      }

      if (
        propertyTenancies.length === 1 &&
        property.status === LettingStatus.DeterminedByTenancies
      ) {
        const tenancy = propertyTenancies[0];
        return [
          ...acc,
          {
            ...baseProperty,
            link: `${ROUTES.LETTING.ROOT}/${property.propertyId}/${tenancy.tenancyId}`,
            isManaged: tenancy.lettingType === ManagedType.MANAGED,
            status: tenancy.status,
          },
        ];
      }

      if (propertyTenancies.length === 1) {
        const tenancy = propertyTenancies[0];
        return [
          ...acc,
          {
            ...baseProperty,
            tenancies: [
              {
                link: `${ROUTES.LETTING.ROOT}/${property.propertyId}`,
                status: property.status,
                tenancyId: '',
                isCurrent: false,
                isManaged: property.lettingType === ManagedType.MANAGED,
              },
              {
                ...tenancy,
                link: `${ROUTES.LETTING.ROOT}/${property.propertyId}/${tenancy.tenancyId}`,
                status: tenancy.status,
                isManaged: tenancy.lettingType === ManagedType.MANAGED,
              },
            ],
          },
        ];
      }

      if (propertyTenancies.length > 1) {
        const formattedTenancies = propertyTenancies.map((tenancy) => ({
          ...tenancy,
          link: `${ROUTES.LETTING.ROOT}/${property.propertyId}/${tenancy.tenancyId}`,
          status: tenancy.status,
          isManaged: tenancy.lettingType === ManagedType.MANAGED,
        }));

        const activeTenancies = formattedTenancies.filter((tenancy) =>
          ACTIVE_TENANCIES.includes(tenancy.status),
        );

        const inactiveTenancies = formattedTenancies?.reduce(
          (accum: PortfolioTenancy[], tenancy: PortfolioTenancy) => {
            const existingCompletedTenancy = accum.find(
              (t) => t.status === LettingStatus.Complete,
            );
            if (
              INACTIVE_TENANCIES.includes(tenancy.status) &&
              !existingCompletedTenancy
            ) {
              return [...accum, tenancy];
            }
            return accum;
          },
          [],
        );

        const passiveTenancies = formattedTenancies.filter((tenancy) =>
          SECONDARY_TENANCIES.includes(tenancy.status),
        );

        const tenancies = [
          ...activeTenancies,
          ...passiveTenancies,
          ...inactiveTenancies,
        ].slice(0, 2);

        if (property.status !== LettingStatus.DeterminedByTenancies) {
          return [
            ...acc,
            {
              ...baseProperty,
              tenancies: [
                {
                  link: `${ROUTES.LETTING.ROOT}/${property.propertyId}`,
                  status: property.status,
                  tenancyId: '',
                  isCurrent: false,
                  isManaged: property.lettingType === ManagedType.MANAGED,
                },
                tenancies[0],
              ],
              link: `${ROUTES.LETTING.ROOT}/${property.propertyId}`,
            },
          ];
        }

        return [
          ...acc,
          {
            ...baseProperty,
            tenancies,
            link: `${ROUTES.LETTING.ROOT}/${property.propertyId}/${
              tenancies[0]?.tenancyId || ''
            }`,
          },
        ];
      }

      return acc;
    },
    [],
  );

  const sortedProperties = [...allProperties].sort((a, b) => {
    const addressA = a.address || '';
    const addressB = b.address || '';
    if (addressA < addressB) {
      return -1;
    }
    if (addressA > addressB) {
      return 1;
    }
    return 0;
  });

  return sortedProperties;
};

export const formatCompanyPortfolio = (
  companyPortfolios: PortfolioProperty[],
) => {
  const companyPortfolioList = companyPortfolios?.reduce(
    (acc: CompanyPortfolio[], companyPortfolio: PortfolioProperty) => {
      const companyPortfolioExists = acc.find(
        (company) => company.companyId === companyPortfolio.companyId,
      );

      if (companyPortfolioExists) {
        return acc;
      }

      return [
        ...acc,
        {
          companyId: companyPortfolio.companyId,
          companyName: companyPortfolio.companyName,
          link: `${ROUTES.LETTING.COMPANY_PORTFOLIO}/${companyPortfolio.companyId}`,
        },
      ] as CompanyPortfolio[];
    },
    [],
  );

  return companyPortfolioList?.map((company) => ({
    ...company,
    properties: companyPortfolios.filter(
      (property) => property.companyId === company.companyId,
    ),
  }));
};

export const getFilteredProperties = (
  portfolioProperties: PortfolioProperties,
  t9n: T9nHubResource,
) => {
  const allProperties = [];
  if (portfolioProperties?.personalProperties?.length > 0) {
    allProperties.push({
      title: t9n.PortfolioTitle,
      type: PortfolioTypes.PERSONAL,
      properties: formatProperties(portfolioProperties.personalProperties),
    });
  }

  const companyPortfolioProperties = formatCompanyPortfolio(
    portfolioProperties?.companyProperties,
  );

  companyPortfolioProperties?.forEach((companyPortfolio) => {
    allProperties.push({
      title: companyPortfolio.companyName,
      type: PortfolioTypes.COMPANY,
      properties: formatProperties(companyPortfolio.properties),
      link: companyPortfolio.link,
    });
  });

  return allProperties;
};

export const getPropertiesAndTenancyCount = (
  properties: SidebarPortfolioProperty[],
) => {
  return properties?.reduce((acc, property) => {
    const tenancyCount =
      property.tenancies?.filter(
        (tenancy) => !HIDDEN_TENANCIES.includes(tenancy.status),
      ).length || 0;
    const totalCount = acc + tenancyCount + 1;
    return totalCount;
  }, 0);
};
