import { TOAST_AUTOCLOSE, TOAST_ZINDEX } from 'constants/global';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

const toastRoot = document.getElementById('toast-root');
export class ToastPortal extends React.PureComponent {
  render() {
    if (!toastRoot) {
      return null;
    }
    return ReactDOM.createPortal(
      <ToastContainer
        autoClose={TOAST_AUTOCLOSE}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: TOAST_ZINDEX }}
      />,
      toastRoot,
    );
  }
}
