export const enum ErrorStates {
  NOT_FOUND = 'NotFound',
  SERVER_ERROR = 'ServerError',
  PROPERTY_ERROR = 'PropertyError',
  FORBIDDEN = 'Forbidden',
  UNAVAILABLE_PREVIEW = 'UnavailablePreview',
  FATAL_ERROR = 'FatalError',
}

export interface ErrorPageTypes {
  readonly errorType: ErrorStates;
}
