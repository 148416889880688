import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { hideVisually } from 'polished';
import styledTheme from 'styled/theme';
import { ReactComponent as SavillsLogo } from 'assets/icons/savills-square.svg';
import { ReactComponent as BellSvg } from 'assets/icons/bell.svg';
import { ReactComponent as CalendarSvg } from 'assets/icons/calendar.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/file.svg';
import { ReactComponent as HouseSvg } from 'assets/icons/house-new.svg';
import { ReactComponent as PeopleSvg } from 'assets/icons/people.svg';
import { ReactComponent as UserSvg } from 'assets/icons/user.svg';
import { ReactComponent as PortfolioSvg } from 'assets/icons/portfolio.svg';
import { ReactComponent as Arrow } from 'assets/icons/mobx-left-chevron.svg';
import { SidebarContainerProps, SidebarItemProps } from './types';

const DRAWER_ANIMATION_TIME = '0.4s';
const DRAWER_ANIMATION_EASING = 'ease';
const PROPERTY_LINE_HEIGHT = '40px';

export const SIDEBAR_WIDTHS = {
  sml: '223px',
  lrg: '320px',
};

function withIconStyles(Icon: React.FC, fill: string, stroke: string) {
  return styled(Icon)`
    position: relative;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    margin: 0;
    fill: ${fill};

    path {
      stroke: ${stroke};
    }
  `;
}

export const SidebarControl = styled.div`
  width: 100vw;
  height: 56px;
  background: ${({ theme }) => theme.hub.colorPalette.primary.white};
  position: sticky;
  top: 0;
  z-index: 1000;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    display: none;
  }
`;

export const SidebarMenuButton = styled.button`
  width: 56px;
  height: 56px;
  margin: 0 10px;
  border: none;
  outline: none;
  background: none;
  position: fixed;
  top: 0;

  svg {
    fill: ${(props) => props.theme.colorPalette.branding.white};
  }

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  svg {
    fill: ${({ theme }) => theme.hub.colorPalette.primary.white};
  }
`;

export const SidebarWrapper = styled.div<SidebarContainerProps>`
  grid-area: sidebar;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.hub.colorPalette.primary.darkBlue};
  color: ${(props) => props.theme.hub.colorPalette.primary.white};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  height: 100%;
  width: calc(100vw);
  ${({ isOpen }) => (isOpen ? `left: 0;` : `left: -100vw;`)}
  transition: left 0.3s ease;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    left: 0;
    max-width: ${SIDEBAR_WIDTHS.sml};
  }
  @media screen and (${(props) => props.theme.media.widthXXlrg}) {
    max-width: ${SIDEBAR_WIDTHS.lrg};
  }
`;

export const Logo = styled(SavillsLogo)`
  height: 50px;
  width: 50px;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    height: 66px;
    width: 66px;
  }
`;

export const SidebarCloseButton = styled(SidebarMenuButton)`
  position: absolute;
  top: 24px;
  right: 0;
  @media screen and (${(props) => props.theme.media.widthSml}) {
    display: none;
  }

  &:hover,
  &:focus {
    opacity: 1;
    svg {
      fill: ${(props) => props.theme.colorPalette.branding.yellow};

      path {
        fill: ${(props) => props.theme.colorPalette.branding.yellow};
      }
    }
  }
`;

export const SidebarHeader = styled.header`
  padding: 22px 22px 15px;
  a {
    line-height: 0;
    display: inline-block;
  }
`;

export const SidebarNav = styled.ul`
  margin: 0;
  display: flex;
  height: 100%;
  flex-flow: column;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  // hide scrollbars
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SidebarNavItem = styled.li<SidebarItemProps>`
  font-weight: ${(props) =>
    props.isUppercase
      ? props.theme.fonts.secondary.fontWeightMed
      : props.theme.fonts.secondary.fontWeight};
  text-transform: ${(props) => (props.isUppercase ? 'uppercase' : 'none')};
  font-size: ${(props) => props.theme.hub.fontSize.menu.medium};
  border-top: 1px solid
    ${(props) => props.theme.hub.colorPalette.primary.greyBlue};
  position: relative;
  width: 100%;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.hub.colorPalette.primary.white};
    width: fill-available;
  }

  &:first-child {
    border-top: 0;
    border-bottom: 0;
  }
  ${(props) =>
    props.isFixedToBottom &&
    css`
      margin-top: auto;
    `}

  ${(props) =>
    props.isPropertyItem &&
    css`
      flex-flow: column wrap;
    `}


  ${(props) =>
    props.isUppercase &&
    css`
      letter-spacing: 2px;
      font-size: ${props.theme.typography.xsmall};
    `}

${(props) => props.iconRightAligned && `justify-content: space-between;`}


  ${(props) =>
    props.isActive &&
    css`
      border-left: 4px solid ${props.theme.colorPalette.branding.yellow};
    `}
`;

export const SidebarNavContent = styled(Link)<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  padding: ${(props) => (props.isActive ? '18px 20px' : '18px 24px')};

  a {
    display: flex;
    flex-flow: row nowrap;
    grid-gap: 10px;
    position: relative;
    z-index: 10;

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }

  ${(props) =>
    props.isDisabled &&
    css`
      svg {
        fill: ${props.theme.hub.colorPalette.primary.greyBlue};

        path {
          stroke: ${props.theme.hub.colorPalette.primary.greyBlue};
        }
      }
    `}
`;

export const PropertyNavList = styled.ul`
  letter-spacing: normal;
  font-size: ${(props) => props.theme.typography.small};
  text-transform: none;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  margin: 0;
`;

export const PropertyNavHeader = styled.span`
  padding: 24px 30px;
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 10px;
  text-transform: uppercase;
`;

export const PropertyNavItem = styled.li<{
  hideBorder?: boolean;
  isActiveRoute?: boolean;
}>`
  grid-gap: 5px;
  width: 100%;
  padding: 0 0 10px;
  border-left: 4px solid ${(props) => props.theme.colorPalette.branding.ink};
  border-bottom: ${(props) =>
    props.hideBorder
      ? '0'
      : `1px solid
    ${props.theme.hub.colorPalette.primary.greyBlue}`};
  ${(props) =>
    props.isActiveRoute &&
    `
      border-left: 4px solid ${props.theme.colorPalette.branding.yellow};
    `}
`;

export const PropertyNavItemDefault = styled(PropertyNavItem)<{
  isActive?: boolean;
}>`
  padding: 18px 10px 18px 16px;
  margin: 0;
  border-bottom: 1px solid
    ${(props) => props.theme.hub.colorPalette.primary.greyBlue};
  a {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    padding: 0 0 0 8px;

    span {
      padding-top: 2px;
    }

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }

  ${(props) =>
    props.isActive &&
    `
      padding: 18px 10px 18px 12px;
      border-left: 4px solid ${props.theme.colorPalette.branding.yellow};
    `}
`;

export const PropertyNavItemDisabled = styled(PropertyNavItem)`
  padding: 7px 10px 7px 30px;
  opacity: 0.5;

  display: flex;
  grid-gap: 10px;
  align-items: center;

  span {
    padding-top: 2px;
  }
`;

const getActiveStyles = (isActive?: boolean) => {
  return isActive
    ? styledTheme.colorPalette.branding.yellow
    : styledTheme.colorPalette.branding.ink;
};

export const PropertyNavTitle = styled.button<{
  isOpen?: boolean;
  isActiveRoute?: boolean;
}>`
  display: flex;
  grid-gap: 10px;
  padding: 18px 0 8px 20px;
  width: 100%;
  background: none;
  border: 0;
  color: ${(props) => props.theme.colorPalette.branding.white};
  text-align: left;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.hub.fontSize.menu.small};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  text-transform: uppercase;
  align-items: center;
  letter-spacing: 2px;
  border-left: 4px solid ${(props) => getActiveStyles(props.isActiveRoute)};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorPalette.branding.yellow};
    &::after {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }
`;
export const PropertyTitle = styled(Link)`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.hub.fontSize.menu.small};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const PropertyList = styled.ul<{ items: number; isOpen: boolean }>`
  height: 0;
  overflow: hidden;
  margin: ${(props) => (props.isOpen ? '10px 0' : '0')};
  opacity: 0;
  padding-bottom: 0;

  ${({ isOpen, items }) =>
    isOpen &&
    `
    opacity: 1;
    height: calc(${items} * ${PROPERTY_LINE_HEIGHT});
  `};
  transition: all ${DRAWER_ANIMATION_TIME} ${DRAWER_ANIMATION_EASING};
`;

export const PropertyLineItem = styled.li<{ isSelected?: boolean }>`
  display: inline-block;
  width: 100%;
  font-size: ${({ theme }) => theme.hub.fontSize.menu.medium};
  text-align: left;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};

  a {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 10px 7px 46px;
    background: ${(props) => props.theme.colorPalette.branding.ink};
    border-left: 4px solid
      ${(props) =>
        props.isSelected
          ? props.theme.colorPalette.branding.yellow
          : props.theme.colorPalette.branding.ink};
    &:hover,
    &:focus {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }
`;

export const BellIcon = withIconStyles(
  BellSvg,
  styledTheme.colorPalette.branding.white,
  'none',
);
export const CalendarIcon = withIconStyles(
  CalendarSvg,
  styledTheme.colorPalette.branding.white,
  'none',
);
export const DocumentIcon = withIconStyles(
  DocumentSvg,
  styledTheme.colorPalette.branding.white,
  'none',
);
export const HouseIcon = withIconStyles(
  HouseSvg,
  styledTheme.colorPalette.branding.white,
  styledTheme.colorPalette.branding.white,
);
export const PeopleIcon = withIconStyles(
  PeopleSvg,
  'none',
  styledTheme.colorPalette.branding.white,
);
export const UserIcon = withIconStyles(
  UserSvg,
  'none',
  styledTheme.colorPalette.branding.white,
);

export const PortfolioIcon = withIconStyles(
  PortfolioSvg,
  styledTheme.colorPalette.branding.white,
  styledTheme.colorPalette.branding.white,
);
export const VisuallyHidden = styled.span`
  ${hideVisually}
`;

export const ExpandIcon = styled(Arrow)`
  position: relative;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  margin: 0;
  fill: ${styledTheme.colorPalette.branding.white};
  transform: rotate(90deg);
  margin: 0 30px 0 auto;

  path {
    stroke: ${styledTheme.colorPalette.branding.white};
  }
`;

export const CollapseIcon = styled(ExpandIcon)`
  transform: rotate(270deg);
`;

export const SidebarAccountNav = styled.ul`
  border-top: 1px solid
    ${(props) => props.theme.hub.colorPalette.primary.greyBlue};
`;

export const SidebarDisabledLink = styled.span`
  cursor: disabled;
  color: ${(props) => props.theme.hub.colorPalette.primary.greyBlue};
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 10px;
  position: relative;
  z-index: 10;
  opacity: 0.5;

  align-items: center;
`;

export const LettingNavList = styled.ul`
  margin: 0;
  font-size: ${({ theme }) => theme.typography.small};
  font-family: ${({ theme }) => theme.fonts.secondary.font};

  button {
    margin-top: 10px;
  }
`;

export const LettingNavItem = styled.li`
  padding: 0;
  position: relative;
`;

export const PortfolioPropertiesList = styled.ul<{
  isOpen?: boolean;
  items?: number;
}>`
  margin: ${(props) => (!props.isOpen ? '0 15px 0 0' : '0px 15px 0 0')};
  padding: 0;
  list-style: none;
  height: 0;
  overflow: hidden;
  opacity: 0;

  ${({ isOpen, items }) =>
    isOpen &&
    `
    opacity: 1;
    height: auto;
  `};
  transition: all ${DRAWER_ANIMATION_TIME} ${DRAWER_ANIMATION_EASING};
`;

export const PortfolioPropertiesListItem = styled.li<{ isSelected?: boolean }>`
  padding: 10px 0;
  display: flex;
  width: 100%;
  flex-direction: column;

  a {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px 2px 46px;
    border-left: 4px solid
      ${(props) =>
        props.isSelected
          ? props.theme.colorPalette.branding.yellow
          : props.theme.colorPalette.branding.ink};
    &:hover,
    &:focus {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }
`;

export const TenancyList = styled.ul<{ isOpen?: boolean }>`
  margin: 0;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  & > li:first-child a {
    padding-top: 10px;
  }
`;

export const TenancyListItem = styled.li`
  padding: 0;
  display: flex;
`;

export const PortfolioNavTitle = styled.button<{
  isOpen?: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  grid-gap: 10px;
  padding: ${(props) =>
    props.isSelected ? ' 10px 25px 10px  20px' : ' 10px 25px 10px 24px'};
  width: 100%;
  background: none;
  border: 0;
  color: ${(props) => props.theme.colorPalette.branding.white};
  text-align: left;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.hub.fontSize.menu.medium};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  align-items: center;

  ${(props) =>
    props.isSelected &&
    `border-left: 4px solid ${props.theme.colorPalette.branding.yellow};`}

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorPalette.branding.yellow};
    &::after {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }
`;

export const PortfolioPropertyTitle = styled.span`
  display: flex;
  grid-gap: 10px;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.hub.fontSize.menu.medium};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  padding: 0 30px 0 50px;

  svg {
    margin: 0;
  }
`;

export const StyledExpandCollapse = styled.span<{ isNested?: boolean }>`
  position: absolute;
  width: 16px;
  right: ${(props) => (props.isNested ? '0' : '20px')};
`;
